.navigation-points-container {
  position: fixed;
  background-color: rgb(50, 50, 50);
  width: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  gap: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-radius: 10px 0 0 10px;
  z-index: 2;
}
.navigation-point {
  text-align: center;
  background-color: rgb(150, 150, 150);
  padding: 0.2em;
  color: transparent;
  text-decoration: none;
  height: 10px;
  width: 10px;
}
.navigation-point:hover {
  color: transparent;
  background-color: white;
  transition: 0.5s;
}
.active-section-nav {
  background-color: #004CBF !important;
}