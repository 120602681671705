.products {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3em;
  overflow: hidden;
}
.products-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
}
.container-product-item {
  background: #004CBF;
  transform: rotate(3deg);
  height: 100%;
}
.product-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2em;
  background-color: white;
  box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.3);
  transform: rotate(-3deg);
}
.product-item h3 {
  font-size: 2em;
  color: #004CBF;
}
@media (max-width: 600px) {
  .products {
    height: 100%;
  }
  .products-container {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
}