.card-item {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  box-shadow: 2px 5px 7px 3px rgba(0, 0, 0, 0.2);
  padding: 1.8em;
  border-radius: 30px;
  height: 100%;
  min-height: 20em;
  width: 2%;
  max-width: 100%;
  overflow: hidden;
  transition: width 0.5s ease, max-height 0.5s ease;
}
.expanded {
  width: 100%;
  max-height: none;
  border-radius: 0;
  border-bottom: 5px solid #004CBF;
  transition: 0.2s;
}
.icon-item {
  background-color: white;
  border-radius: 100%;
  padding: 0.5em;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.2);
}
.card-item:hover .icon-item {
  transform: translateY(-10px);
  transition: transform 0.2s;
}
.icon-item path {
  fill: #004CBF;
}
.shadow-text {
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1),2px 2px 5px rgba(0, 0, 0, 1),-2px -2px 5px rgba(0, 0, 0, 1);
}
