.areas {
  height: 100vh;
  overflow: hidden;
}
.our-areas {
  padding-top: 5em;
}
.gifs-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 80%;
}
.gif-content {
  min-width: 100px;
  min-height: 100px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom: 5px solid #004CBF;
  box-shadow: 2px 4px 5px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em 1em 0.5em;
}
.gif-content img {
  width: 100%;
  padding: 1em;
}
.gif-content p {
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0;
}
.technologies {
  padding-left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
}
.slider {
	overflow: hidden;
	width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
}
.slide-track {
	display: flex;
  justify-content: center;
  align-self: center;
	width: calc(250px * 46);
	animation: scroll 50s linear infinite;
}
.slide-track:hover {
  animation-play-state: paused;
}
.slide {
  width: 250px;
	height: 5em;
	line-height: 4.8em;
	text-align: center;
}
.slider::before, .slider::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, rgb(30, 30, 30) 0%, rgba(255,255,255,0) 100%);
  z-index: 1;
}
.slider::before {
  top: 0;
  left: 0;
}
.slider::after {
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
}
.slide img {
  height: 3em;
}
@keyframes scroll {
	0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 23));
  }
}
@media (max-width: 600px) {
  .areas {
    height: 100%;
  }
  .our-areas {
    padding: 2em;
    padding-bottom: 1.5em;
  }
  .technologies {
    padding-bottom: 1.5em;
  }
  .slide-track {
    animation: scroll 30s linear infinite;
  }
  .slider::before, .slider::after {
    width: 100px;
  }
  .gifs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .slider {
    margin: 1em 0;
  }
}