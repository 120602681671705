.navbar {
  position: fixed;
  z-index: 3;
}
.home-header {
  background-color: rgb(50, 50, 50);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
}
.scroll-down-black {
  box-shadow: none;
  background: transparent;
  background-image: linear-gradient(to bottom, rgba(30, 30, 30, 1) 0%, rgba(30, 30, 30, 0.7) 50%, rgba(30, 30, 30, 0) 100%);
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.scroll-down-white {
  box-shadow: none;
  background: transparent;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0) 100%);
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.bg-burguer {
  display: flex;
  justify-content: flex-end;
}
.text-nav {
  text-transform: uppercase;
  font-size: 0.9em;
  color: rgb(170, 170, 170);
}
.text-nav:focus {
  outline: none;
  color: rgb(170, 170, 170);
}
.text-nav:hover {
  color: white;
  transition: 0.5s;
}
.dropdown-menu.show {
  left: 50%!important;
  transform: translate(-50%, 0)!important;
}
.flag-lang {
  width: 4em;
}
.box-lang {
  min-width: 4em;
}
.inverter-burger-color{
  filter: brightness(0) invert(1);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.contact {
  border-radius: 0;
  font-size: 0.9em;
  text-transform: uppercase;
}
.contact-white {
  border: 1px solid rgb(170, 170, 170);
  color: rgb(170, 170, 170);
}
.contact-white:focus {
  border-color: rgb(170, 170, 170);
  color: rgb(170, 170, 170);
  outline: none;
}
.contact-black {
  border: 1px solid rgb(70, 70, 70);
  color: rgb(90, 90, 90);
}
.contact-black:focus {
  border-color: rgb(70, 70, 70);
  color: rgb(90, 90, 90);
  outline: none;
}
.contact:hover {
  border-radius: 5px; 
  border-color: white;
  color: white;
  transition: 0.5s;
  outline: none;
}
.public-icon path {
  fill: rgb(170, 170, 170);
}
.icon-action:hover path {
  fill: white;
  transition: 0.5s;
}
@media (max-width: 600px) {
  .bg-burguer {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .text-nav {
    margin-left: 0;
    margin-right: 0;
  }
  .btn-login {
    padding-top: 1em;
  }
  .btn-home,
  .btn-about,
  .btn-identity,
  .btn-blog,
  .btn-areas {
    display: none;
  }
}