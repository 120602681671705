.contact-cont {
  text-align: center;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center; 
  min-height: 100vh;
}
.modal-content {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.btn-close:focus {
  box-shadow: none;
}
.title-contact-blue, .title-contact-orange {
  font-size: 2.8em;
  font-weight: 900;
  letter-spacing: -0.03em;
}
.title-contact-orange {
  color: rgb(70, 70, 70);
}
.title-contact-blue {
  color: #004CBF;
}
.contact-subtitle {
  font-weight: 700;
  color: rgb(70, 70, 70);
  margin-bottom: -0.1em;
}
.desc-contact {
  color: rgb(70, 70, 70);
}
.desc-contact b {
  color: #004CBF;
}
.btn-send {
  background: none;
  color: #004CBF;
  border: 1px solid #004CBF;
  transition: transform 0.2s ease;
  text-transform: uppercase;
}
.btn-send:hover {
  border-radius: 5px;
  background: #004CBF;
  color: white;
  transition: 0.2s;
  outline: none;
}