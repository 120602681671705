.cont-text-area label{
  display: block;
  border-bottom: 2px solid rgb(150, 150, 150);
  padding: 8px 10px;
  position: relative;
  box-sizing: border-box;
  margin-top: 1.2em;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
}
.cont-text-area label:hover{
  border-bottom: 2px solid #004CBF;
  transition: 0.5s;
}
.cont-text-area label.focus{
  border-bottom: 2px solid #004CBF;
}
.cont-text-area label span{
  color: rgb(150, 150, 150);
  position: absolute;
  top: 8px;
  left: 10px;
  transition: .25s;
  margin-left: 5px;
}
.cont-text-area span.focus{
  color: #004CBF;
}
.cont-text-area span.top{
  left: 0;
  top: -18px;
  font-size: 12px;
  padding: 0 5px;
}
.cont-text-area label textarea{
  color: black;
  display: block;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}