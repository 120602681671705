.main-banner {
  background-image: url('../../assets/images/main-banner.webp');
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.cont-banner {
  color: white;
  padding-top: 17em;
}
.subtitle-banner {
  padding-left: 1rem;
}
.title-banner, .title-banner-pasion {
  font-size: 4em;
  font-weight: 900;
  padding-left: 1rem;
  letter-spacing: -0.05em;
}
.title-banner-pasion {
  background-image: linear-gradient(to right, #004CBF, #F14124);
  display: inline-block;
  --tw-skew-x: -12deg;
  transform: skewX(var(--tw-skew-x));
}

@media (max-width: 600px) {
  .main-banner {
    background-image: url('../../assets/images/main-banner-phone.webp');
  }
  .cont-banner{
    text-align: center;
  }
  .title-banner, .title-banner-pasion{
    font-size: 1.8em;
  }
  .subtitle-banner{
    padding-left: 0;
  }
}
.btn-our-products {
  position: relative;
  display: inline-block;
  margin-top: 2em;
  padding: 1.5em;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  overflow: hidden;
  font-size: 0.8em;
}
.btn-our-products:hover {
  background: white;
  color: rgb(70, 70, 70);
  border-radius: 15px;
}
.btn-our-products:hover span:nth-child(1),
.btn-our-products:hover span:nth-child(2),
.btn-our-products:hover span:nth-child(3),
.btn-our-products:hover span:nth-child(4) {
  background: white;
}
.btn-our-products span {
  position: absolute;
  display: block;
  background: linear-gradient(90deg, transparent, white);
  animation: animate1 2s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
}
.btn-our-products span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, white);
  animation: animate1 2s linear infinite;
}
.btn-our-products span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, white);
  animation: animate2 2s linear infinite;
  animation-delay: 0.50s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%, 100% {
    top: 100%;
  }
}
.btn-our-products span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, white);
  animation: animate3 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%, 100% {
    right: 100%;
  }
}
.btn-our-products span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, white);
  animation: animate4 2s linear infinite;
  animation-delay: 1.50s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%, 100% {
    bottom: 100%;
  }
}